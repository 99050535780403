/**
 * Die verfügbaren Quellen aus denen importiert werden kann
 */
export enum ImportSource {
  /**
   * Keine Quelle
   */
  None = 0,
  /**
   * Datensatz aus Offline-Modus
   */
  Offline = 1
}
