// import moment from 'moment'
import { Filter } from '@/interfaces/Filter.interface'
import { DatabaseComparator } from '@/constants/database-comparator.enum'
import { Conversion } from '@/constants/conversion.enum'
import { BaseDataset } from '@/interfaces/ContentLoader/base-dataset.interface'
import { Request } from '@/interfaces/ContentLoader/request.interface'
import { CustomPaginated } from '@/interfaces/CustomPaginated.interface'
import { OrderDirection } from './order-direction.enum'
import { typeSourceCallback } from '@/helpers/contentLoader/source.helper'
import { formatToIsoDate } from '@/helpers/value-format'

/**
 * Klasse für die Komponente [[LazyContentLoader]]. Bereitet eine Liste von Daten
 * auf, damit diese als Datenquelle durch die Komponente [[LazyContentLoader]]
 * verwendet werden kann. Es werden alle Information der Grundabfrage der
 * Komponente [[LazyContentLoader]] unterstützt.
 */
export class ClientSource<T extends BaseDataset = BaseDataset> {
  /**
   * Liste mit allen verfügbaren Einträgen
   */
  private sourceData: T[]

  /**
   * Einstellung mit der Information wie die Einträge gefiltert werden sollen.
   */
  public request: Request | null = null

  /**
   * Erzeugt eine neue Instance der Klasse [[ClientSource]].
   *
   * @param source - Alle verfügbaren Einträge
   */
  public constructor(source: T[]) {
    this.sourceData = source
  }

  /**
   * Generiert eine gefilterte und sortierte Liste an Datensätzen basierend auf
   * [[request]]. Ist [[request]] nicht gesetzt, wird die Originalstruktur
   * zurückgegeben.
   *
   * @returns Die gefilterte Liste.
   */
  public getFilteredList(): T[] {
    return this.request !== null
      ? this.sortedList(this.filteredList(this.sourceData))
      : this.sourceData
  }

  /**
   * Gibt eine passende Funktion für [[LazyContentLoader.source]] zurück.
   *
   * @returns Arrow-Funktion für [[LazyContentLoader.source]]
   */
  public getSourceCallback(): typeSourceCallback {
    // eslint-disable-next-line require-await
    return (request: Request): Promise<CustomPaginated<BaseDataset>> => {
      const result = new Promise<CustomPaginated<BaseDataset>>(
        (resolve, reject): void => {
          try {
            this.request = request
            const entries = this.getFilteredList()
            const offset = request.$offset

            resolve({
              limit: request.$limit,
              skip: request.$offset,
              total: entries.length,
              data: entries.slice(offset, offset + request.$limit)
            })
          } catch (ex) {
            reject(ex)
          }
        }
      )

      return result
    }
  }

  /**
   * Filtert die angegebenen Einträge. Für die Filterungseinstellung wird das
   * Property [[ClientSource.request]] verwendet.
   *
   * @param source - Liste mit allen Einträgen die gefiltert werden sollen.
   * @returns - Gefilterte Liste mit nur passenden Einträgen.
   */
  private filteredList(source: T[]): T[] {
    if (this.request === null) {
      return source
    }

    const filters: Filter[] = this.request.$filters
    const searchText = (this.request.$search || '').trim().toLowerCase()
    const searchColumns: string[] | undefined = this.request.$searchColumns

    if (filters.length <= 0 && !searchText) {
      return source
    }

    return source.filter((entry): boolean => {
      // Globale Suche
      if (searchText !== '') {
        const cloned = { ...entry }
        const properties = Object.keys(entry)

        for (const property of properties) {
          const value = entry[property]

          if (typeof value === 'string' && value !== '') {
            this.determineMatchingString(
              property,
              value,
              searchText,
              cloned,
              searchColumns
            )
          }
        }

        if (!cloned.matchingChecks) {
          return false
        }
      }

      // Filter
      return this.checkFilters(entry, filters)
    })
  }

  /**
   * Sortiert die angegebenen Einträge. Für die Sortierung wird das Property
   * [[ClientSource.request]] verwendet.
   *
   * @param source - Liste mit allen Einträgen die sortiert werden sollen.
   * @returns - Sortierte Liste mit allen Einträgen.
   */
  private sortedList(source: T[]): T[] {
    if (this.request === null) {
      return source
    }

    const orderDir = this.request.$orderDirection
    const orderBy = this.request.$orderBy

    return source.sort((a: T, b: T): number => {
      let A
      let B

      if (orderDir === OrderDirection.Ascending) {
        A = a[orderBy]
        B = b[orderBy]
      } else {
        B = a[orderBy]
        A = b[orderBy]
      }

      if (typeof A === 'string' && typeof B === 'string') {
        A = this.replaceSpecialChars(A.toLowerCase())
        B = this.replaceSpecialChars(B.toLowerCase())
      }

      if (typeof A !== 'undefined' && typeof B !== 'undefined') {
        if (A > B) {
          return 1
        }

        if (B > A) {
          return -1
        }
      }

      return 0
    })
  }

  /**
   * Überprüft, ob ein Wert in der Liste mit dem gesuchten Begriff übereinstimmt
   *
   * @param property - Name der Eigenschaft, dessen Wert gerade geprüft wird
   * @param value - Aktuell zu prüfender Wert in Spalte
   * @param search - Der aktuelle Suchbegriff
   * @param entry - Eintrag mit dem zu prüfenden Wert
   * @param checkable - Spalten die geprüft werden müssen
   */
  private determineMatchingString(
    property: string,
    value: string,
    search: string,
    entry: BaseDataset,
    checkable?: string[]
  ): void {
    if (
      !entry.matchingChecks &&
      value.toString().toLowerCase().includes(search)
    ) {
      if (Array.isArray(checkable) && checkable.length !== 0) {
        if (checkable.includes(property)) {
          entry.matchingChecks = true
          entry.alreadyChecked = true
        } else {
          entry.matchingChecks = false
          entry.alreadyChecked = true
        }
      } else {
        entry.matchingChecks = true
        entry.alreadyChecked = true
      }
    } else if (
      entry.alreadyChecked === false &&
      Array.isArray(checkable) &&
      checkable.length !== 0
    ) {
      entry.matchingChecks = false
    }
  }

  /**
   * Prüft ob der angegebene Eintrag die angegebenen Filtern erfüllt.
   *
   * @param entry - Eintrag der geprüft wird.
   * @param filters - Liste mit Filtern, die der Eintrag erfüllen muss.
   * @returns - Ob der Eintrag zu den Filtern erfüllt.
   */
  private checkFilters(entry: T, filters: Filter[]): boolean {
    let success = true

    for (const filter of filters) {
      if (!entry.hasOwnProperty(filter.column)) {
        success = false
        break
      }

      let value = entry[filter.column]
      let filterValue: (string | number | boolean | Date | undefined)[] = []

      if (filter.conversion === Conversion.Boolean) {
        value = !!value
        filterValue = filter.values.map(
          (x): boolean => x === 'true' || x === '1'
        )
      } else if (filter.conversion === Conversion.Date) {
        if (!(value instanceof Date)) {
          success = false
          break
        }

        filterValue = filter.values
          .map(formatToIsoDate)
          .filter((x): boolean => !!x)
          .map((x): Date => new Date(x as string))
      } else if (filter.conversion === Conversion.Number) {
        if (typeof value !== 'number') {
          success = false
          break
        }

        value = value.toString()
        filterValue = filter.values
          .map(parseFloat)
          .filter((x): boolean => !isNaN(x))
          .map((x): string => x.toString())
      } else if (!value || typeof value.toString !== 'function') {
        success = false
        break
      } else {
        value = value.toString()
        filterValue = filter.values
      }

      if (filterValue.length) {
        switch (filter.comparator) {
          case DatabaseComparator.Equal:
          default:
            if (value === filterValue[0]) {
              continue
            }
            break

          case DatabaseComparator.NotEqual:
            if (value !== filterValue[0]) {
              continue
            }
            break

          case DatabaseComparator.Bigger:
            if (filterValue[0] && value > filterValue[0]) {
              continue
            }
            break

          case DatabaseComparator.Smaller:
            if (filterValue[0] && value < filterValue[0]) {
              continue
            }
            break

          case DatabaseComparator.BiggerOrEqual:
            if (filterValue[0] && value >= filterValue[0]) {
              continue
            }
            break

          case DatabaseComparator.SmallerOrEqual:
            if (filterValue[0] && value <= filterValue[0]) {
              continue
            }
            break

          case DatabaseComparator.Like:
            if (
              typeof value === 'string' &&
              typeof value.indexOf === 'function' &&
              filterValue[0] &&
              value.indexOf(filterValue[0].toString()) !== -1
            ) {
              continue
            }
            break

          case DatabaseComparator.Between:
            if (
              filterValue[0] &&
              filterValue[1] &&
              value >= filterValue[0] &&
              value <= filterValue[1]
            ) {
              continue
            }
            break

          case DatabaseComparator.In:
            if (filterValue.indexOf(value) !== -1) {
              continue
            }
            break
        }
      }

      success = false
      break
    }

    return success
  }

  /**
   * Ersetzt spezielle Zeichen wie Umlaute für Sortierung
   *
   * @param value - String mit bspw. Umlauten
   * @returns String mit ersetzten Zeichen
   */
  public replaceSpecialChars(value: string): string {
    value = value.toLowerCase()
    value = value.replace(/ä|å|á|ã/g, 'a')
    value = value.replace(/ó|ö|ð|õ/g, 'o')
    value = value.replace(/ü|ú|ũ/g, 'u')
    value = value.replace(/í/g, 'i')
    value = value.replace(/ñ/g, 'n')
    value = value.replace(/ß/g, 's')
    return value
  }
}
