
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { beforeRouteEnter, beforeRouteUpdate } from './AuditList.before-router'
import { Audit } from '@/components/ChecklistBuilder/Misc/audit.interface'
import LazyContentLoader from '@/components/LazyContentLoader/LazyContentLoader.vue'
import { Column } from '@/interfaces/ContentLoader/column.interface'
import { ColumnFilterOptionItem } from '@/interfaces/ContentLoader/column-filter-option-item.interface'
import { ClientSource } from '@/components/LazyContentLoader/client-source.class'
import { BaseDataset } from '@/interfaces/ContentLoader/base-dataset.interface'
import { Request } from '@/interfaces/ContentLoader/request.interface'
import { CustomPaginated } from '@/interfaces/CustomPaginated.interface'
import { typeSourceCallback } from '@/helpers/contentLoader/source.helper'
import { ListEntryAudit } from '@/offline/services/audit/list-entry-audit.interface'
import { Connection } from '@/offline/connection.enum'
import { getService, onReady } from '@/helpers/feathers'
import { formatListValue } from '@/helpers/value-format'
import { convertOfflineAudit } from '@/helpers/audits/convert-offline-audit'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'

/**
 * View für die Auflistung aller Online und Offline Audits.
 */
@Component({
  components: {
    LazyContentLoader,
    BaseFrame
  },
  beforeRouteEnter: beforeRouteEnter,
  beforeRouteUpdate: beforeRouteUpdate
})
export default class AuditList extends Vue {
  /**
   * Enthält alle Offline-Audits des Benutzers
   */
  public offlineSource: ClientSource | null = null

  /**
   * Die Funktion von den Offline-Audits für [[LazyContentLoader.source]].
   */
  public offlineSourceCallback: typeSourceCallback | null = null

  /**
   * Einstellungen von dem Dialog für das Konvertieren aller Onnline-Audits.
   */
  public dlgConvertOfflineAudits = {
    hasEntries: false,
    display: false,
    step: 'start',
    color: 'primary',
    isCalculate: true,
    percentage: 100,
    countTotal: 0,
    countCompleted: 0,
    countFailed: 0,
    countSuccess: 0
  }

  /**
   * Welche Audits in der Auflistung angezeigt werden sollen.
   */
  @Prop({
    validator: (value): boolean => value === 'online' || value === 'offline',
    required: true
  })
  public usedContent!: 'online' | 'offline'

  /**
   * Spalte nach den die Inhalte sortiert werden sollen.
   */
  public orderBy = 'due'

  /**
   * Enthält alle Checklisten-Kategorien die über die Filter ausgefählt werden
   * können.
   */
  private checklistCategories: ColumnFilterOptionItem[] = []

  /**
   * Enthält die entsprechende Arrow-Funktion für [[LazyContentLoader.source]].
   *
   * @returns Promise mit dem Ergebnis von der Datenquelle.
   */
  public sourceCallback: typeSourceCallback = (): Promise<
    CustomPaginated<BaseDataset>
  > => {
    return new Promise<CustomPaginated<BaseDataset>>(
      (resolve, reject): void => {
        reject(new Error(`'buildSourceCallback' method isn't called`))
      }
    )
  }

  /**
   * Gibt die Spalten für die Komponente `LazyContentLoader` zurück. Diese sind
   * abhängig von [[this.usedContent]].
   *
   * @returns - Liste mit den Spalten, die Angezeigt werden sollen.
   */
  public get columns(): Column[] {
    const columns: Column[] = [
      {
        listPosition: 'primary',
        listOnly: true,
        property: 'label',
        formatAs: 'string',
        formatted: '{labelPrefix} {label}{labelSubstance|| (??) }{labelSuffix}'
      },
      {
        listPosition: 'secondary',
        formatAs: 'string',
        property: 'categoryLabel',
        formatted: '{due} {categoryLabel||– ??}',
        listOnly: true
      },
      {
        label: 'auditList.column.due',
        property: 'due',
        formatAs: 'date',
        searchable: true,
        width: 110
      },
      {
        label: 'auditList.column.name',
        property: 'labelComplete',
        formatAs: 'string',
        formatted: '{labelPrefix} {label}{labelSubstance|| (??) }{labelSuffix}',
        searchable: true
      },
      {
        label: 'auditList.column.category',
        property: 'category',
        formatAs: 'string',
        formatted: '{categoryLabel}',
        searchable: true,
        width: 200
      },
      {
        label: 'auditList.column.category',
        property: 'categoryID',
        formatAs: 'string',
        filterAs: 'select',
        filterOptions: this.checklistCategories,
        searchable: true,
        sortable: false,
        listOnly: true
      },
      {
        label: 'auditList.column.responsible',
        property: 'responsible',
        formatAs: 'string',
        formatted: '{responsibleFirstName} {responsibleLastName}',
        searchable: true,
        width: 200
      },
      {
        label: 'auditList.column.executor',
        property: 'executor',
        formatAs: 'string',
        formatted: '{executorFirstName} {executorLastName}',
        searchable: true,
        width: 200
      },
      {
        label: 'auditList.column.affected',
        property: 'affected',
        formatAs: 'string',
        formatted: '{affectedFirstName} {affectedLastName}',
        searchable: true,
        width: 200
      },
      {
        label: 'auditList.column.creator',
        property: 'createdBy',
        formatAs: 'string',
        formatted: '{createdByFirstName} {createdByLastName}',
        searchable: true,
        width: 200
      }
    ]

    if (this.usedContent === 'online') {
      columns.push({
        label: 'auditList.column.actions',
        description: 'auditList.filter.actionTrue',
        property: 'action',
        formatAs: 'string',
        formatted: '{actionDone}{actionTotal||/??}',
        sortable: false,
        searchable: true,
        filterAs: 'switch',
        width: 100
      })
    }

    columns.push(
      {
        listPosition: 'actionIcon',
        description: 'auditList.column.progress',
        property: 'progress',
        formatAs: 'icon',
        sortable: false,
        width: 60,
        iconFor: (value, entry): string =>
          this.getAuditInfoByStatus(
            entry,
            'mdi-clipboard-outline',
            'mdi-clipboard-text-outline',
            'mdi-clipboard-check-outline'
          ),
        iconColor: (value, entry): string =>
          this.getAuditInfoByStatus(
            entry,
            'deep-orange lighten-1',
            'lime darken-1',
            'success'
          )
      },
      // Filter: Nur offene Audits anzeigen
      {
        label: 'auditList.filter.doneTrue',
        property: 'done',
        formatAs: 'icon',
        sortable: false,
        searchable: true,
        listOnly: true
      },
      // Filter: Nur abgeschlossene Audits anzeigen
      {
        label: 'auditList.filter.doneFalse',
        property: 'done-false',
        formatAs: 'icon',
        sortable: false,
        searchable: true,
        listOnly: true
      }
    )

    return columns
  }

  /**
   * Vue Hook `created`. Lädt die Checklist-Kategorien, die für die Filter
   * benötigt werden und sorgt dafür, dass die Funktion für das Laden der Daten
   * von einer Quelle auf eine gültige Quelle gesetzt wird.
   */
  public async created(): Promise<void> {
    this.buildSourceCallback()

    const serviceCategories = await getService<BaseDataset>(
      'offline-data/checklist-categories'
    )

    this.checklistCategories = await serviceCategories
      .find()
      .then(
        (result): BaseDataset[] =>
          Array.isArray(result)
            ? result
            : Array.isArray(result.data)
            ? result.data
            : [],
        (): BaseDataset[] => []
      )
      .then((result): ColumnFilterOptionItem[] => {
        const options: ColumnFilterOptionItem[] = []

        for (const item of result) {
          if (typeof item.name === 'string' && typeof item.id === 'number') {
            options.push({
              text: item.name,
              value: item.id.toString()
            })
          }
        }

        return options
      })
  }

  /**
   * Wandelt das Audit in einen Listeneintrag um.
   *
   * @param audit - Audit, welches umgewandelt werden soll
   * @param currentUser - Aktueller angemeldeter Benutzer
   * @returns - Listeneintrag
   */
  private buildListEntryEntry(
    audit: Audit,
    currentUser: BaseDataset,
    [values, category, responsible, executor, affected]: BaseDataset[]
  ): ListEntryAudit {
    const due = audit.due ? new Date(audit.due) : null
    const completedAt = audit.completedAt ? new Date(audit.completedAt) : null
    const createdAt = audit.createdAt ? new Date(audit.createdAt) : null

    const entry: ListEntryAudit = {
      id: audit.id || 0,
      due: due && !isNaN(due.getTime()) ? due : undefined,
      done: typeof audit.done === 'boolean' ? audit.done : false,
      completedAt:
        completedAt && !isNaN(completedAt.getTime()) ? completedAt : undefined,
      label: audit.label ? audit.label.toString() : '',
      labelPrefix: audit.labelPrefix ? audit.labelPrefix.toString() : '',
      labelSuffix: audit.labelSuffix ? audit.labelSuffix.toString() : '',
      labelSubstance: '',
      categoryID: typeof category.id === 'number' ? category.id : 0,
      categoryLabel: `${category.name || ''}`,
      responsibleID: typeof responsible.id === 'number' ? responsible.id : 0,
      responsibleLastName: `${responsible.lastName || ''}`,
      responsibleFirstName: `${responsible.firstName || ''}`,
      responsiblePersonnelNumber: `${responsible.personnelNumber || ''}`,
      executorID: typeof executor.id === 'number' ? executor.id : 0,
      executorLastName: `${executor.lastName || ''}`,
      executorFirstName: `${executor.firstName || ''}`,
      executorPersonnelNumber: `${executor.personnelNumber || ''}`,
      affectedID: typeof affected.id === 'number' ? affected.id : 0,
      affectedLastName: `${affected.lastName || ''}`,
      affectedFirstName: `${affected.firstName || ''}`,
      affectedPersonnelNumber: `${affected.personnelNumber || ''}`,
      createdAt:
        createdAt && !isNaN(createdAt.getTime()) ? createdAt : undefined,
      createdByID: typeof currentUser.id === 'number' ? currentUser.id : 0,
      createdByLastName: `${currentUser.lastName || ''}`,
      createdByFirstName: `${currentUser.firstName || ''}`,
      createdByPersonnelNumber: `${currentUser.personnelNumber || ''}`,
      isStarted: values.isStarted === true,
      progressDone: audit.progressDone,
      progressTotal: audit.progressTotal,
      actionDone: 0,
      actionTotal: 0
    }

    this.columns.forEach((column): void => {
      if (
        !entry.hasOwnProperty(column.property) &&
        column.formatAs !== 'icon' &&
        column.formatAs !== 'check' &&
        (column.searchable === true || column.sortable !== false)
      ) {
        entry[column.property] = formatListValue(
          this.$i18n,
          entry,
          '',
          column.formatAs,
          column.formatted,
          column.iconTrue,
          column.iconFalse,
          column.iconFor
        )
      }
    })

    return entry
  }

  /**
   * Erstellt eine Instance von [[ClientSource]] für die Offline-Audit und gibt
   * diesen Callback-Funktion für [[LazyContentLoader.source]] zurück.
   *
   * @returns Die Callback-Funktion für [[LazyContentLoader.source]].
   */
  private async initOfflineSource(): Promise<typeSourceCallback> {
    let callback

    if (this.offlineSourceCallback !== null) {
      callback = this.offlineSourceCallback
    } else {
      const entries: ListEntryAudit[] = []
      // Offline-Services laden
      const [
        serviceChecklistCellValues,
        serviceChecklistCategories,
        serviceEmployees,
        serviceAudits
      ] = await Promise.all([
        getService<BaseDataset>('checklists/cells/values', Connection.Offline),
        getService<BaseDataset>(
          'offline-data/checklist-categories',
          Connection.Offline
        ),
        getService<BaseDataset>('offline-data/employees', Connection.Offline),
        getService<Audit>('audits/offline', Connection.Offline)
      ])
      await new Promise((success): void => onReady(success))
      const userID = this.$store.getters['user/id']
      const filter = { createdBy: userID }
      // Anzahl der Offline-Audits zählen und auf die Userdaten warten
      const [total, currentUser] = await Promise.all([
        serviceAudits.find({ query: { ...filter, $limit: 0 } }).then(
          (result): number =>
            'total' in result && typeof result.total === 'number'
              ? result.total
              : 0,
          (): number => 0
        ),
        serviceEmployees.get(userID).catch((): BaseDataset => ({}))
      ])
      let limit = 0
      // alle Offline-Audits laden
      for (let i = 0; i < total; i += limit) {
        const result = await serviceAudits.find({
          query: { ...filter, $offset: i }
        })

        if (!('data' in result) || !Array.isArray(result.data)) {
          break
        }

        if (!limit && typeof result.limit === 'number') {
          limit = result.limit

          if (limit <= 0) {
            break
          }
        }

        for (const audit of result.data) {
          if (typeof audit.id !== 'number' || !Number.isInteger(audit.id)) {
            continue
          }

          const loadContent: (BaseDataset | Promise<BaseDataset>)[] = []
          // Prüfen, ob Audit bereits gestartet wurde
          loadContent.push(
            serviceChecklistCellValues
              .find({
                query: {
                  $limit: 0,
                  selector: `audit_${audit.id}`,
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  collection_id: { $in: audit.checklists }
                }
              })
              .then(
                (result): BaseDataset =>
                  'total' in result && typeof result.total === 'number'
                    ? { isStarted: result.total > 0 }
                    : { isStarted: false }
              )
          )
          // Kategorie des Audits
          if (typeof audit.categoryID === 'number') {
            loadContent.push(
              serviceChecklistCategories
                .get(audit.categoryID)
                .catch((): BaseDataset => ({}))
            )
          } else {
            loadContent.push({})
          }
          // verantwortlichen Mitarbeiter
          if (typeof audit.responsibleID === 'number') {
            if (audit.responsibleID === currentUser.id) {
              loadContent.push(currentUser)
            } else {
              loadContent.push(
                serviceEmployees
                  .get(audit.responsibleID)
                  .catch((): BaseDataset => ({}))
              )
            }
          } else {
            loadContent.push({})
          }
          // ausführenden Mitarbeiter
          if (typeof audit.executorID === 'number') {
            if (audit.executorID === currentUser.id) {
              loadContent.push(currentUser)
            } else {
              loadContent.push(
                await serviceEmployees
                  .get(audit.executorID)
                  .catch((): BaseDataset => ({}))
              )
            }
          } else {
            loadContent.push({})
          }
          // betroffenen Mitarbeiter
          if (typeof audit.affectedEmployeeID === 'number') {
            if (audit.affectedEmployeeID === currentUser.id) {
              loadContent.push(currentUser)
            } else {
              loadContent.push(
                serviceEmployees
                  .get(audit.affectedEmployeeID)
                  .catch((): BaseDataset => ({}))
              )
            }
          } else {
            loadContent.push({})
          }

          // alle Abfragen abwarten und Listeneintrag erstellen
          const content: BaseDataset[] = await Promise.all<BaseDataset>(
            loadContent
          ).catch((): BaseDataset[] => [{}, {}, {}, {}, {}])
          entries.push(this.buildListEntryEntry(audit, currentUser, content))
        }
      }

      let source: ClientSource
      this.offlineSource = source = new ClientSource(entries)
      this.offlineSourceCallback = callback = source.getSourceCallback()
    }

    return callback
  }

  /**
   * Gibt eine passende Funktion für [[LazyContentLoader.source]] zurück. Diese
   * übernimmt das Erweitern der Filtern und leitet die Abfrage an die richtige
   * Datenquelle weiter.
   */
  protected buildSourceCallback(): void {
    let cache: Request | null = null

    this.dlgConvertOfflineAudits.countTotal = 0
    this.onConvertOfflineAudit('updateHasEntries')

    this.sourceCallback =
      this.usedContent !== 'offline'
        ? async (request: Request): Promise<CustomPaginated<BaseDataset>> => {
            if (this.offlineSourceCallback !== null) {
              this.offlineSource = null
              this.offlineSourceCallback = null
            }

            const result: CustomPaginated<BaseDataset> = await (
              await getService<CustomPaginated<BaseDataset>>('audit')
            )
              .find({ query: request })
              .then((response): CustomPaginated<BaseDataset> => {
                if (!('skip' in response) || !Array.isArray(response.data)) {
                  throw new Error(
                    `response isn't from type 'CustomPaginated<T>'`
                  )
                }

                return response as CustomPaginated<BaseDataset>
              })

            return result
          }
        : async (
            request: Request,
            list
          ): Promise<CustomPaginated<BaseDataset>> => {
            let result: CustomPaginated<BaseDataset>

            if (cache !== null && this.offlineSourceCallback === null) {
              cache = request
              result = { limit: -1, skip: -1, total: 0, data: [] }
            } else {
              cache = request
              const callback = await this.initOfflineSource()
              result = await callback(cache, list)

              this.dlgConvertOfflineAudits.countTotal = result.total
              this.onConvertOfflineAudit('updateHasEntries')
            }

            return result
          }
  }

  /**
   * Gibt für das übergbene Audit jeweils einen der angegebenen Werte anhand
   * seines Status zurück.
   *
   * @param entry - Audit, an dem der Rückgabewert bestimmt wird.
   * @param forStatusNew - Rückgabewert bei dem Status 'Neu'.
   * @param forStatusEdit - Rückgabewert bei dem Status 'In Bearbeitung'.
   * @param forStatusCompleted - Rückgabewert bei dem Status 'Abgeschlossen'.
   * @returns - Der entsprechende Rückgabewert.
   */
  public getAuditInfoByStatus(
    entry: BaseDataset,
    forStatusNew: string,
    forStatusEdit: string,
    forStatusCompleted: string
  ): string {
    return entry.isStarted === true
      ? entry.progressTotal !== entry.progressDone
        ? forStatusEdit
        : forStatusCompleted
      : forStatusNew
  }

  /**
   * Öffnet die Übersichtseite des Audits, von der Zeile in der Ansicht die
   * durch den Benutzer angeklickt worden ist. Ist kein Audit als Parameter
   * angegeben oder die ID ist ungültig (kleiner oder gleich 0) passiert nichts.
   *
   */
  @Watch('usedContent')
  public onUsedContentChnage(): void {
    this.buildSourceCallback()
  }

  /**
   * Öffnet die Übersichtseite des Audits, von der Zeile in der Ansicht die
   * durch den Benutzer angeklickt worden ist. Ist kein Audit als Parameter
   * angegeben oder die ID ist ungültig (kleiner oder gleich 0) passiert nichts.
   *
   * @param entry - Audit, dass geöffnet werden soll
   */
  public onRowClick(entry?: Audit): void {
    if (entry && entry.id && entry.id > 0) {
      if (this.usedContent === 'online') {
        // direkt Online-Audits in eplas10l öffnen
        window.location.href = `/index.php/9/audit/edit/${entry.id.toString()}`
      } else {
        this.$router.push({
          name: 'audit_view',
          params: {
            content: this.usedContent,
            id: entry.id.toString()
          }
        })
      }
    }
  }

  /**
   * Steuert den Dialog für die Konvertierung aller Offline-Audits.
   *
   * @param fnc - Welche Funktion des Dialoges ausgefürt werden soll.
   */
  public onConvertOfflineAudit(fnc: string): void {
    const dlgData = this.dlgConvertOfflineAudits

    if (fnc === 'updateHasEntries') {
      dlgData.hasEntries =
        this.usedContent === 'offline' &&
        this.$store.getters['connectivity/online'] &&
        dlgData.countTotal > 0
    } else if (fnc === 'showDialog') {
      this.onConvertOfflineAudit('updateHasEntries')

      if (dlgData.hasEntries) {
        dlgData.color = 'primary'
        dlgData.step = 'start'
        dlgData.display = true
      } else {
        dlgData.display = false
      }
    } else if (fnc === 'startImport') {
      if (
        this.$store.getters['connectivity/online'] &&
        dlgData.hasEntries &&
        dlgData.step === 'working'
      ) {
        dlgData.countFailed = 0
        dlgData.countSuccess = 0
        dlgData.countCompleted = 0
        dlgData.percentage = 0
        dlgData.isCalculate = true

        getService<Audit>('audits/offline', Connection.Offline).then(
          async (service): Promise<void> => {
            const userID = this.$store.getters['user/id'] as number
            let total = dlgData.countTotal
            let limit = 1

            for (let offset = 0; offset < total; offset += limit) {
              const result = await service
                .find({ query: { $offset: offset, createdBy: userID } })
                .catch((): object => ({}))

              if ('total' in result && Array.isArray(result.data)) {
                if (limit !== result.limit) {
                  limit = result.limit
                  total = result.total

                  if (dlgData.isCalculate) {
                    dlgData.isCalculate = false
                  }
                }

                for (const audit of result.data) {
                  await convertOfflineAudit('audit', audit)
                    .then(
                      (): void => {
                        ++dlgData.countSuccess
                      },
                      (): void => {
                        ++dlgData.countFailed
                      }
                    )
                    .then((): void => {
                      const count = ++dlgData.countCompleted
                      dlgData.percentage = Math.round((count / total) * 100)

                      if (count === total) {
                        setTimeout((): void => {
                          dlgData.color =
                            dlgData.countFailed !== 0
                              ? dlgData.countFailed !== total
                                ? 'warning'
                                : 'error'
                              : 'success'
                          dlgData.step = 'completed'
                        }, 500)
                      }
                    })
                }
              }
            }
          }
        )
      }
    } else if (fnc === 'hideDialog') {
      if (dlgData.step === 'completed') {
        this.$router.go(0)
      } else if (dlgData.step !== 'working') {
        dlgData.display = false
      }
    } else if (fnc === 'triggerConvert') {
      if (dlgData.step === 'start') {
        dlgData.step = 'working'
        this.onConvertOfflineAudit('startImport')
      }
    }
  }
}
