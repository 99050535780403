import { Route } from 'vue-router'
import { i18n } from '@/plugins/i18n'

/**
 * Diese Funktion wird von der View [[AuditList]] verwendet. Es wird der Routen-
 * Parameter `content` auf gültigkeit geprüft und der passende Seitentitel
 * gesetzt. Ist der Parameter ungültig wird zur Index-Seite weitergeleitet.
 *
 * @param to - Routen-Information von der Zielseite.
 * @param from - Routen-Information von der Quellseite.
 * @param next - Funktion zum wechseln/laden der nächste Seite.
 */
function beforeRoute(
  to: Route,
  from: Route,
  next: (to?: string) => void
): void {
  if (to.params.content !== 'online' && to.params.content !== 'offline') {
    next('/')
  } else {
    const index = document.title.lastIndexOf('-')

    if (to.meta !== undefined) {
      to.meta.title = i18n.t(
        `sideNavigation.listItem.audits${to.params.content[0].toUpperCase()}` +
          `${to.params.content.slice(1)}`
      )

      document.title =
        index !== -1
          ? `${to.meta.title} ${document.title.substring(index)}`
          : to.meta.title
    }

    next()
  }
}

/**
 * Funktion "beforeRouteEnter" der View [[Auditlist]].
 */
export const beforeRouteEnter = beforeRoute

/**
 * Funktion "beforeRouteUpdate" der View [[Auditlist]].
 */
export const beforeRouteUpdate = beforeRoute
